// Axios instance and types
import http from '../api'
import { APIResponse, Filters, APIPaginatedResponse, Pagination } from '../types'
import { TypeCreateRoomTable, TypeRoomTable } from './types'
import { getRequestParamString } from '../utils'

const apiPath = import.meta.env.VITE_API_PATH

async function getRoomTables(filters?: Partial<Filters>, pagination?: Partial<Pagination>) {
  return await http.get<APIPaginatedResponse<any>>(apiPath + 'room/table' + getRequestParamString(filters, pagination))
}

async function deleteRoomTable(RoomTableId: number) {
  return await http.delete<APIResponse<boolean>>(`${apiPath}room/table/${RoomTableId}`)
}

async function createRoomTable(input: TypeCreateRoomTable) {
  const payLoad = {
    name: input.name,
    description: input.description,
  }
  return await http.post<APIResponse<any>>(apiPath + 'room/table', payLoad)
}

async function updateRoomTable(input: TypeRoomTable) {
  const payLoad = {
    name: input.name,
    description: input.description,
  }
  return await http.patch<APIResponse<boolean>>(`${apiPath}room/table/${input.id}`, payLoad)
}

async function getTableOfRoomId(roomId: number) {
  return await http.get<APIPaginatedResponse<any>>(apiPath + 'room/table/' + roomId)
}

export default {
  getRoomTables,
  deleteRoomTable,
  createRoomTable,
  updateRoomTable,
  getTableOfRoomId,
}
