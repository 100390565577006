// Axios instance and types
import http from '../../api'
import { APIResponse, Filters, APIPaginatedResponse, Pagination } from '../../types'
import { TypeCreateOrder, TypeOrder, TypeOrderMenuStatus } from './types'
import { getRequestParamString, parseDate } from '../../utils'

const apiPath = import.meta.env.VITE_API_PATH

const getPayLoad = (input: TypeOrder | TypeCreateOrder) => {
  return {
    client_uuid: input.client_uuid,
    order_date: input.order_date ? parseDate('' + input.order_date) : '',
    order_items: input.order_items ? input.order_items : [],
    description: input.description,
    room_id: input.room_id,
    table_id: input.table_id,
    staff_id: input.staff_id,
  }
}

async function fetch(filters?: Partial<Filters>, pagination?: Partial<Pagination>) {
  return await http.get<APIPaginatedResponse<any>>(apiPath + 'order' + getRequestParamString(filters, pagination))
}

async function fetchMenuOrders(filters?: Partial<Filters>, pagination?: Partial<Pagination>) {
  return await http.get<APIPaginatedResponse<any>>(
    apiPath + 'order/menus/list' + getRequestParamString(filters, pagination),
  )
}

async function fetchMenuOrderStats(filters?: Partial<Filters>, pagination?: Partial<Pagination>) {
  return await http.get<APIPaginatedResponse<any>>(
    apiPath + 'order/statistics/daily' + getRequestParamString(filters, pagination),
  )
}

async function remove(ItemUuid: string) {
  return await http.delete<APIResponse<boolean>>(`${apiPath}order/${ItemUuid}`)
}

async function create(input: TypeCreateOrder) {
  return await http.post<APIResponse<any>>(apiPath + 'order', getPayLoad(input))
}

async function update(input: TypeOrder) {
  return await http.patch<APIResponse<boolean>>(`${apiPath}order/${input.uuid}`, getPayLoad(input))
}

async function getOrderOfUuid(orderUuid: string) {
  return await http.get<APIPaginatedResponse<any>>(apiPath + 'order/' + orderUuid)
}

async function getFilteredOrderStatus(currentStatus?: number) {
  return await http.get<APIPaginatedResponse<any>>(apiPath + 'order/orderstatuses/list?status=' + currentStatus)
}

async function updateOrderMenuStatus(input: TypeOrderMenuStatus) {
  const parLoad = {
    status: input.status,
    description: input.description,
  }
  return await http.patch<APIResponse<boolean>>(`${apiPath}order/status/${input.menu_uuid}`, parLoad)
}

export default {
  fetch,
  remove,
  create,
  update,
  getOrderOfUuid,
  fetchMenuOrders,
  updateOrderMenuStatus,
  getFilteredOrderStatus,
  fetchMenuOrderStats,
}
