// Axios instance and types
import http from '../api'
import { TypeUserLogin } from './types'
import router from '../../router'
import { APIResponse, Filters, APIPaginatedResponse, Pagination } from '../types'
import { getRequestParamString } from '../utils'
// import { TypeCreateRoom, TypeRoom } from "./types";

const apiPath = import.meta.env.VITE_API_PATH

async function getUsers(filters?: Partial<Filters>, pagination?: Partial<Pagination>) {
  return await http.get<APIPaginatedResponse<any>>(apiPath + 'users' + getRequestParamString(filters, pagination))
}

async function userLogin(input: TypeUserLogin) {
  // Get csrf-cookie
  await http.get('/sanctum/csrf-cookie')
  return await http.post<string>(`${apiPath}auth/login`, input)
}

async function userLogOut() {
  return await http.post<string>(`${apiPath}auth/logout`).then(() => {
    router.push('login')
  })
}

async function getAuthenticatedUser() {
  return await http.get<any>(`${apiPath}auth/user`)
}

async function getUserPermissions() {
  return await http.get<APIResponse<any>>(`${apiPath}auth/permissions`)
}

export default {
  userLogin,
  userLogOut,
  getAuthenticatedUser,
  getUserPermissions,
  getUsers,
}
