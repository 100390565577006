// Axios instance and types
import http from '../../api'
import { APIResponse, Filters, APIPaginatedResponse, Pagination } from '../../types'
import { TypeCreateItemCategory, TypeUpdateItemCategory } from './types'
import { getRequestParamString } from '../../utils'

const apiPath = import.meta.env.VITE_API_PATH

async function getItemCategories(filters?: Partial<Filters>, pagination?: Partial<Pagination>) {
  return await http.get<APIPaginatedResponse<any>>(
    apiPath + 'item-categories' + getRequestParamString(filters, pagination),
  )
}

async function deleteItemCategory(ItemUuid: string) {
  return await http.delete<APIResponse<boolean>>(`${apiPath}item-categories/${ItemUuid}`)
}

async function createItemCategory(input: TypeCreateItemCategory) {
  const payLoad = {
    name: input.name,
    description: input.description,
  }
  return await http.post<APIResponse<any>>(apiPath + 'item-categories', payLoad)
}

async function updateItemCategory(input: TypeUpdateItemCategory) {
  const payLoad = {
    name: input.name,
    description: input.description,
  }
  return await http.patch<APIResponse<boolean>>(`${apiPath}item-categories/${input.uuid}`, payLoad)
}

async function getItemCategoriesOfItemGroupId(itemGroupId: string) {
  return await http.get<APIPaginatedResponse<any>>(apiPath + 'item-categories/item-group/' + itemGroupId)
}

export default {
  getItemCategories,
  deleteItemCategory,
  createItemCategory,
  updateItemCategory,
  getItemCategoriesOfItemGroupId,
}
