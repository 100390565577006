// Axios instance and types
import http from '../../api'
import { APIResponse, Filters, APIPaginatedResponse, Pagination } from '../../types'
import { TypeCreateMenu, TypeMenu } from './types'
import { getRequestParamString } from '../../utils'

const apiPath = import.meta.env.VITE_API_PATH

const getPayLoad = (input: TypeMenu | TypeCreateMenu) => {
  return {
    name: input.name,
    price: input.price,
    avatar: input.avatar,
    availability: input.availability,
    // start_time: input.start_time ? parseDateToTime(input.start_time) : '',
    // end_time: input.end_time ? parseDateToTime(input.end_time) : '',
    description: input.description,
    category_id: input.category_id,
    menu_items: input.menu_items ? input.menu_items : [],
  }
}

async function fetch(filters?: Partial<Filters>, pagination?: Partial<Pagination>) {
  return await http.get<APIPaginatedResponse<any>>(apiPath + 'menu' + getRequestParamString(filters, pagination))
}

async function remove(ItemUuid: string) {
  return await http.delete<APIResponse<boolean>>(`${apiPath}menu/${ItemUuid}`)
}

async function create(input: TypeCreateMenu) {
  return await http.post<APIResponse<any>>(apiPath + 'menu', getPayLoad(input))
}

async function update(input: TypeMenu) {
  return await http.patch<APIResponse<boolean>>(`${apiPath}menu/${input.uuid}`, getPayLoad(input))
}

async function getMenuOfUuid(menuUuid: string) {
  return await http.get<APIPaginatedResponse<any>>(apiPath + 'menu/' + menuUuid)
}

export default {
  fetch,
  remove,
  create,
  update,
  getMenuOfUuid,
}
