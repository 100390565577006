import router from '../../router'
import { API } from '../../services'
import { Filters, Pagination } from '../../services/types'
import { HttpStatusCode } from 'axios'
import { TypeUserLogin, TypeUser } from '../../services/user/types'
import { defineStore } from 'pinia'
import { useToast } from 'vuestic-ui'
import { defaultPagination, defaultSorting } from '../../services/defaults'
import { setPagination } from '../../services/utils'

const { init } = useToast()

/** Client Store */
export const useUserStore = defineStore('userStore', {
  // Default Config State
  state: () => ({
    loading: false,
    permissions: [] as string[],
    authUser: {} as object,
    users: [] as TypeUser[],
    filters: {} as Filters,
    pagination: defaultPagination,
    sorting: defaultSorting,
  }),
  // Actions
  actions: {
    initLoading() {
      this.loading = true
    },
    stopLoading() {
      this.loading = false
    },
    setPermissions(permission: string[]) {
      this.permissions = permission
    },
    setAuthUser(user: object) {
      this.authUser = user
    },
    setPagination(pagination: Pagination) {
      this.pagination = setPagination(pagination)
    },

    setUsers(users: TypeUser[]) {
      this.users = users
    },

    hasPermission(permission: string): boolean {
      return this.permissions.includes(permission)
    },

    // Users methods.
    async dispatchGet(): Promise<any> {
      this.initLoading()
      const { status, data } = await API.user.getUsers(this.filters, this.pagination).finally(this.stopLoading)

      if (status === HttpStatusCode.Ok) {
        // set rooms
        this.setUsers(data.data)
        this.setPagination({
          meta: data.meta,
        })
      }

      return {
        success: status == HttpStatusCode.Ok,
      }
    },

    async dispatchUserLogin(input: TypeUserLogin): Promise<void> {
      this.initLoading()

      const { status } = await API.user.userLogin(input).finally(this.stopLoading)

      if (status === HttpStatusCode.Ok) {
        init({ message: 'Logged in successfully', color: 'success' })
        router.push({ name: 'dashboard' })
      }
    },

    async dispatchGetAuthenticatedUser(): Promise<void> {
      const { status, data } = await API.user.getAuthenticatedUser()

      if (status === HttpStatusCode.Ok) {
        // set setAuthUser
        this.setAuthUser(data.user)
        // set setPermissions
        this.setPermissions(data.permissions)
      }
    },
  },
})
