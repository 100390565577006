// Axios instance and types
import http from '../api'
import { APIResponse, Filters, APIPaginatedResponse, Pagination } from '../types'
import { TypeCreateItemGroup, TypeUpdateItemGroup } from './types'
import { getRequestParamString } from '../utils'

const apiPath = import.meta.env.VITE_API_PATH

async function getItemGroups(filters?: Partial<Filters>, pagination?: Partial<Pagination>) {
  return await http.get<APIPaginatedResponse<any>>(apiPath + 'item-groups' + getRequestParamString(filters, pagination))
}

async function deleteItemGroup(ItemUuid: string) {
  return await http.delete<APIResponse<boolean>>(`${apiPath}item-groups/${ItemUuid}`)
}

async function createItemGroup(input: TypeCreateItemGroup) {
  const payLoad = {
    name: input.name,
    description: input.description,
  }
  return await http.post<APIResponse<any>>(apiPath + 'item-groups', payLoad)
}

async function updateItemGroup(input: TypeUpdateItemGroup) {
  const payLoad = {
    name: input.name,
    description: input.description,
  }
  return await http.patch<APIResponse<boolean>>(`${apiPath}item-groups/${input.uuid}`, payLoad)
}

export default {
  getItemGroups,
  deleteItemGroup,
  createItemGroup,
  updateItemGroup,
}
