// Axios instance and types
import http from '../../api'
import { APIResponse, Filters, APIPaginatedResponse, Pagination } from '../../types'
import { TypeCreateMenuCategory, TypeMenuCategory } from './types'
import { getRequestParamString } from '../../utils'

const apiPath = import.meta.env.VITE_API_PATH

const getPayLoad = (input: TypeMenuCategory | TypeCreateMenuCategory) => {
  return {
    name: input.name,
    loyalty_applicable: input.loyalty_applicable,
    description: input.description,
  }
}

async function fetch(filters?: Partial<Filters>, pagination?: Partial<Pagination>) {
  return await http.get<APIPaginatedResponse<any>>(
    apiPath + 'menu-category' + getRequestParamString(filters, pagination),
  )
}

async function remove(id: number) {
  return await http.delete<APIResponse<boolean>>(`${apiPath}menu-category/${id}`)
}

async function create(input: TypeCreateMenuCategory) {
  return await http.post<APIResponse<any>>(apiPath + 'menu-category', getPayLoad(input))
}

async function update(input: TypeMenuCategory) {
  return await http.patch<APIResponse<boolean>>(`${apiPath}menu-category/${input.id}`, getPayLoad(input))
}

async function getMenuCategoryOfId(id: number) {
  return await http.get<APIPaginatedResponse<any>>(apiPath + 'menu-category/' + id)
}

export default {
  fetch,
  remove,
  create,
  update,
  getMenuCategoryOfId,
}
