// Axios instance and types
import http from '../api'
import { APIResponse, Filters, APIPaginatedResponse, Pagination } from '../types'
import { TypeCreateClient, TypeUpdateClient } from './types'
import { getRequestParamString, parseDate } from '../utils'

const apiPath = import.meta.env.VITE_API_PATH

async function getClients(filters?: Partial<Filters>, pagination?: Partial<Pagination>) {
  return await http.get<APIPaginatedResponse<any>>(apiPath + 'clients' + getRequestParamString(filters, pagination))
}

async function deleteClient(clientUuid: string) {
  return await http.delete<APIResponse<boolean>>(`${apiPath}clients/${clientUuid}`)
}

async function createClient(input: TypeCreateClient) {
  const payLoad = {
    name: input.name,
    phone_number: input.phone,
    email: input.email,
    address: input.address,
    birth_date: parseDate(input.dob),
  }
  return await http.post<APIResponse<any>>(apiPath + 'clients', payLoad)
}

async function updateClient(input: TypeUpdateClient) {
  const payLoad = {
    name: input.name,
    phone_number: input.phone,
    email: input.email,
    address: input.address,
    birth_date: parseDate(input.dob),
  }
  return await http.patch<APIResponse<boolean>>(`${apiPath}clients/${input.uuid}`, payLoad)
}

export default {
  getClients,
  deleteClient,
  createClient,
  updateClient,
}
