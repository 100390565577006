// Axios instance and types
import http from '../api'
import { APIResponse, Filters, APIPaginatedResponse, Pagination } from '../types'
import { TypeCreateRoom, TypeRoom } from './types'
import { getRequestParamString } from '../utils'

const apiPath = import.meta.env.VITE_API_PATH

async function getRooms(filters?: Partial<Filters>, pagination?: Partial<Pagination>) {
  return await http.get<APIPaginatedResponse<any>>(apiPath + 'room' + getRequestParamString(filters, pagination))
}

async function deleteRoom(RoomId: number) {
  return await http.delete<APIResponse<boolean>>(`${apiPath}room/${RoomId}`)
}

async function createRoom(input: TypeCreateRoom) {
  const payLoad = {
    name: input.name,
    description: input.description,
  }
  return await http.post<APIResponse<any>>(apiPath + 'room', payLoad)
}

async function updateRoom(input: TypeRoom) {
  const payLoad = {
    name: input.name,
    description: input.description,
  }
  return await http.patch<APIResponse<boolean>>(`${apiPath}room/${input.id}`, payLoad)
}

export default {
  getRooms,
  deleteRoom,
  createRoom,
  updateRoom,
}
