import { Pagination, Sorting } from './types'

export const defaultPagination: Pagination = {
  page: 1,
  perPage: 30,
  total: 0,
}

export const defaultSorting: Sorting = {
  sortBy: 'name',
  sortingOrder: null,
}
