// Axios instance and types
import http from '../api'
import { APIResponse, Filters, APIPaginatedResponse, Pagination } from '../types'
import { TypeCreateItem, TypeItem } from './types'
import { getRequestParamString } from '../utils'

const apiPath = import.meta.env.VITE_API_PATH

async function getItems(filters?: Partial<Filters>, pagination?: Partial<Pagination>) {
  return await http.get<APIPaginatedResponse<any>>(apiPath + 'items' + getRequestParamString(filters, pagination))
}

async function deleteItem(ItemUuid: string) {
  return await http.delete<APIResponse<boolean>>(`${apiPath}items/${ItemUuid}`)
}

async function createItem(input: TypeCreateItem) {
  const payLoad = {
    name: input.name,
    code: input.code,
    unit_id: input.unit_id,
    item_group_id: input.item_group_id,
    item_category_id: input.item_category_id,
    item_type_id: input.item_type_id,
    expires: input.expires,
    loyalty_applicable: input.loyalty_applicable,
    bar_code: input.bar_code,
    price: input.price,
    notification_level: input.notification_level,
    image_url: input.image_url,
    serial_number: input.serial_number,
    size: input.size,
    color: input.color,
    description: input.description,
    preparation_time: input.preparation_time,
    nutrition_info: input.nutrition_info,
    special_tags: input.special_tags,
    popularity_rating: input.popularity_rating,
    ingredients: input.ingredients,
  }
  return await http.post<APIResponse<any>>(apiPath + 'items', payLoad)
}

async function updateItem(input: TypeItem) {
  const payLoad = {
    name: input.name,
    code: input.code,
    unit_id: input.unit_id,
    item_group_id: input.item_group_id,
    item_category_id: input.item_category_id,
    item_type_id: input.item_type_id,
    expires: input.expires,
    loyalty_applicable: input.loyalty_applicable,
    bar_code: input.bar_code,
    price: input.price,
    notification_level: input.notification_level,
    image_url: input.image_url,
    serial_number: input.serial_number,
    size: input.size,
    color: input.color,
    description: input.description,
    preparation_time: input.preparation_time,
    nutrition_info: input.nutrition_info,
    special_tags: input.special_tags,
    popularity_rating: input.popularity_rating,
    ingredients: input.ingredients,
  }
  return await http.patch<APIResponse<boolean>>(`${apiPath}items/${input.uuid}`, payLoad)
}

export default {
  getItems,
  deleteItem,
  createItem,
  updateItem,
}
