// Axios instance and types
import http from '../../api'
import { APIResponse, Filters, APIPaginatedResponse, Pagination } from '../../types'
import { TypeCreateItemType, TypeUpdateItemType } from './types'
import { getRequestParamString } from '../../utils'

const apiPath = import.meta.env.VITE_API_PATH

async function getItemTypes(filters?: Partial<Filters>, pagination?: Partial<Pagination>) {
  return await http.get<APIPaginatedResponse<any>>(apiPath + 'item-types' + getRequestParamString(filters, pagination))
}

async function deleteItemType(ItemUuid: string) {
  return await http.delete<APIResponse<boolean>>(`${apiPath}item-types/${ItemUuid}`)
}

async function createItemType(input: TypeCreateItemType) {
  const payLoad = {
    name: input.name,
    description: input.description,
  }
  return await http.post<APIResponse<any>>(apiPath + 'item-types', payLoad)
}

async function updateItemType(input: TypeUpdateItemType) {
  const payLoad = {
    name: input.name,
    description: input.description,
  }
  return await http.patch<APIResponse<boolean>>(`${apiPath}item-types/${input.uuid}`, payLoad)
}

async function getItemTypesOfItemCategoryId(itemCategoryId: string) {
  return await http.get<APIPaginatedResponse<any>>(apiPath + 'item-types/item-category/' + itemCategoryId)
}

export default {
  getItemTypes,
  deleteItemType,
  createItemType,
  updateItemType,
  getItemTypesOfItemCategoryId,
}
