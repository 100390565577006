// Axios instance and types
import http from '../../api'
import { APIResponse, Filters, APIPaginatedResponse, Pagination } from '../../types'
import { TypeCreateUnit, TypeUnit } from './types'
import { getRequestParamString } from '../../utils'

const apiPath = import.meta.env.VITE_API_PATH

async function getUnits(filters?: Partial<Filters>, pagination?: Partial<Pagination>) {
  return await http.get<APIPaginatedResponse<any>>(apiPath + 'units' + getRequestParamString(filters, pagination))
}

async function deleteUnit(unitId: number) {
  return await http.delete<APIResponse<boolean>>(`${apiPath}units/${unitId}`)
}

async function createUnit(input: TypeCreateUnit) {
  const payLoad = {
    name: input.name,
    abbreviation: input.abbreviation,
    description: input.description,
  }
  return await http.post<APIResponse<any>>(apiPath + 'units', payLoad)
}

async function updateUnit(input: TypeUnit) {
  const payLoad = {
    name: input.name,
    abbreviation: input.abbreviation,
    description: input.description,
  }
  return await http.patch<APIResponse<boolean>>(`${apiPath}units/${input.id}`, payLoad)
}

export default {
  getUnits,
  deleteUnit,
  createUnit,
  updateUnit,
}
